<template>
  <div v-if="Object.keys(game).length" style="height: 100vh">
    <Launch @launch="launch" ref="launchComponent" />
    <div class="d-flex justify-content-between">
      <h2>{{ game.name }}</h2>
      <vs-button @click="$router.push({ name: 'sprint-wheels-index' })"
        >Ir a Ruedas</vs-button
      >
    </div>
    <div class="emulator-container">
      <div class="emulator-options">
        <div v-if="phase">
          <h3>
            <span> {{ this.$t(`phases.${phase}`) }}: </span>
            <span v-if="timer">
              {{ timer }}
            </span>
          </h3>
        </div>
        <div class="container-row">
          <div class="container-chips">
            <Chips
              :casinoChips="chips"
              :amount="chipSelected"
              @changeChip="changeChip" />
          </div>
        </div>
        <div
          v-if="betArr.length"
          class="d-flex flex-wrap col"
          style="overflow-y: scroll">
          <h5>Apuesta:</h5>
          <div v-for="(betItem, i) in betArr" :key="i" class="bet-item">
            <span>Numero: {{ betItem.number }}</span>
            <span>Monto: {{ betItem.amount }}</span>
          </div>
        </div>
        <div v-if="Object.keys(player).length" class="mt-4">
          <h5>Player: {{ player.username }}</h5>
          <h5>Balance: {{ player.lastBalance.toFixed(2) }}</h5>
        </div>
      </div>
      <WheelNumbers @handleResult="handleBet" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { WheelNumbers, Chips } from ".";
import { Launch } from "@/components";
import { io } from "socket.io-client";
import { CHANGE_PHASE_EVENT } from "@/state/helpers/phases";

export default {
  components: { WheelNumbers, Launch, Chips },
  data() {
    return {
      game: {},
      chipSelected: 0,
      phase: "",
      timer: 0,
      interval: null,
      round: "",
      bet: {},
      betArr: [],
      isBetting: false,
    };
  },
  computed: {
    ...mapGetters({
      success: "wheel/getSuccess",
      player: "wheel/getPlayer",
      chips: "wheel/getChips",
      currency: "wheel/getCurrency",
    }),
  },
  methods: {
    async launch(item) {
      try {
        const { operatorId: operatorUuid, token } = item;
        const data = { operatorUuid, token, casinoId: this.game.uuid };
        await this.setLaunch(data);
        if (!this.success) return this.$swal.fire("Error launch", "", "error");

        this.$refs.launchComponent.modal = false;
        this.createSocket();
      } catch (error) {
        console.log("ERROR LAUNCH", error);
      }
    },
    changeChip(chip) {
      this.chipSelected = chip;
    },
    createSocket() {
      this.socket = io(process.env.VUE_APP_WHEEL, {
        transports: ["websocket"],
        query: {
          gameUuid: this.game.uuid,
          providerId: this.game.providerId,
          userId: this.player.uuid,
        },
      });

      this.listenEvents();
    },
    listenEvents() {
      this.socket.on(CHANGE_PHASE_EVENT, ({ phase, timeWait }) => {
        console.log("this.phase", phase, timeWait);
        this.phase = phase;

        if (this.phase === "bet_time") {
          this.isBetting = true;
          if (timeWait) {
            this.timer = timeWait;
            if (this.interval) return;
            this.createInteral();
          }
        } else {
          if (timeWait && !this.phase === "processing_bet") {
            this.isBetting = false;
            this.timer = timeWait;
            if (this.interval) return;
            this.createInteral();
          } else {
            this.timer = 0;
            clearInterval(this.interval);
          }
        }

        // if (this.phase === phases.wait_result) return this.phase = ''
      });

      this.socket.on("round:start", ({ round }) => {
        const { ID_Ronda } = round;

        this.round = ID_Ronda;
      });

      this.socket.on("round:end", ({ result }) => {
        this.bet = {};
        this.betArr = [];
        this.sendWinSocket();
        this.openNotification("top-right", "success", "Resultado", result);
      });

      this.socket.on("result:transmision", (data) => {
        console.log("data", data);
      });

      this.socket.on("bet:err", () => {
        this.openNotification(
          "top-right",
          "danger",
          "Error",
          "Error enviando apuesta"
        );
      });

      this.socket.on("bet:success", () => {
        this.openNotification("top-right", "success", "Apuesta enviada");
      });

      this.socket.on("winner", ({ win, earnings }) => {
        if (!win)
          return this.openNotification(
            "top-right",
            "danger",
            "Error",
            "NO GANASTE"
          );

        const { earning } = earnings;
        const msg = `FELICIDADES! GANASTE: ${earning}${this.currency.isoCode}`;
        this.openNotification("top-right", "success", "WINNER!", msg);
      });

      this.socket.on("winner:err", () => {
        this.openNotification(
          "top-right",
          "danger",
          "Error",
          "Error consultando winner"
        );
      });

      this.socket.on("user-duplicated", () => {
        window.alert("TIENES UNA SESION ABIERTA");
      });
    },
    sendBetSocket() {
      const bet = this.createBet(Object.values(this.bet));
      console.log("bet", bet);
      this.socket.emit("bet", bet);
    },
    createBet(bet) {
      const betData = {
        player: this.player.uuid,
        operatorId: this.player.operator.uuid,
        gameId: this.game.uuid,
        bet,
        currency: this.currency.isoCode,
        user_id: this.player.userId,
        round: this.round,
        platform: "desktop",
        playerCountry: "VES",
        player_ip: "1.1.1.1",
        userAgent: "Brave",
      };
      return betData;
    },
    sendWinSocket() {
      const winner = {
        player: this.player.uuid,
        game: this.game.uuid,
        roundUuid: this.round,
      };

      this.socket.emit("winner", winner);
    },
    createInteral() {
      this.interval = setInterval(() => {
        this.timer -= 1;
        console.log("this.timer", this.timer);
        if (this.timer === 0) {
          clearInterval(this.interval);
          this.interval = null;
          if (this.isBetting) return this.sendBetSocket();
        }
      }, 1000);
    },
    openNotification(
      position = null,
      color = null,
      title = "title",
      text = "text"
    ) {
      this.$vs.notification({
        position,
        color,
        title,
        text,
      });
    },
    handleBet(number) {
      if (!this.chipSelected) return;
      let amount = 0;
      if (this.bet[number]) {
        const { amount: amountInBet } = this.bet[number];
        amount = amountInBet;
      }

      this.bet[number] = {
        number,
        amount: amount ? amount + this.chipSelected : this.chipSelected,
      };
      console.log("bet", this.bet);
      this.betArr = Object.values(this.bet);
    },
    ...mapActions({
      setLaunch: "wheel/setLaunch",
    }),
  },
  mounted() {
    const { game } = this.$router.currentRoute.params;
    if (!game) return this.$router.push({ name: "sprint-wheels-index" });
    if (!Object.keys(game).length)
      return this.$router.push({ name: "sprint-wheels-index" });
    this.game = game;
  },
  beforeDestroy() {
    this.socket.disconnect();
    return clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.winner-result {
  font-size: 2rem;
  font-weight: bolder;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.emulator-container {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 20% 80%;
}

.emulator-options {
  display: grid;
  grid-template-rows: 20% 40% 20% 20%;
}

.container-row {
  grid-row: 2/3;
}

.bet-item {
  display: inline-flex;
  // max-height: 20px;
  font-size: 16px;
  color: white;
  border-radius: 10px;
  margin: 2px 0;
  padding: 4px;
  align-items: center;
  background-color: #232323;
}
</style>
